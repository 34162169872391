<template>
  <div>
    <b-card :class="{'md-access-denied-height': accessDenied === true}">
      <md-access-denied :is-access-denied="accessDenied" v-if="accessDenied === true" class="mt-40"/>
      <b-card-text>
        <b-container>
          <ValidationObserver ref="form">
            <b-row>
              <b-col cols="12" lg="4">
                <p class="" v-text="$t(locale.messageTitle)"/>

                <ul class="mb-1 pl-2">
                  <li
                      v-for="(message, index) in messageList"
                      :key="index"
                      style="list-style-type: disc"
                      v-text="message"
                  />
                </ul>

                <p class="" v-text="$t(locale.messageFooter)"/>

                <b-row>
                  <b-col
                      v-for="(form, index) in invoiceForm"
                      :key="index"
                      :cols="form.col"
                      :lg="form.lg"
                  >
                    <ValidationProvider
                        #default="{ errors }"
                        :rules="form.rules"
                        v-if="form.label !== 'none'"
                        :name="$t(form.label)"
                    >
                      <b-form-group :label="$t(form.label)">
                        <date-picker
                            v-if="form.type == 'date'"
                            valueType="format"
                            v-model="form.value"
                            class="w-100"
                            prefix-class="xmx"
                            :placeholder="$t(form.label)"
                        />

                        <b-form-input
                            v-if="form.type == 'input'"
                            :placeholder="$t(form.label)"
                            v-model="form.value"
                        />

                        <v-select
                            v-if="form.type == 'select'"
                            v-model="form.value"
                            label="code"
                            :options="paymentTermResults"
                            :placeholder="$t(form.label)"
                            class="resource-selector d-inline-block"
                        />

                        <b-form-textarea
                            v-if="form.type == 'textarea'"
                            v-model="form.value"
                            :placeholder="$t(form.label)"
                            rows="3"
                        />

                        <small class="text-danger" v-text="errors[0]"/>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
              </b-col>

              <b-col cols="12" lg="4">
                <b-card border-variant="secondary">
                  <b-card-text>
                    <div class="d-flex flex-column bigGap">
                      <p class="mb-0" v-text="$t(locale.uploadInvoiceTitle)"/>

                      <span
                          v-if="file"
                          class="font-semibold text-xl text-green-500"
                      >
                        Fil vald
                        <span>
                          <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5 text-green-500"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                          >
                            <path
                                fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clip-rule="evenodd"
                            />
                          </svg>
                        </span>
                      </span>

                      <input
                          hidden
                          type="file"
                          @change="inputImage"
                          ref="imageInput"
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      />

                      <b-button
                          class="mt-2"
                          v-ripple.400
                          @click="uploadImage"
                          variant="flat-primary"
                          v-text="'Ladda upp fil'"
                      />

                      <div class="mt-3">
                        <p class="" v-text="`* ${$t(locale.alertPermited)}`"/>
                        <a href="../../exports/artiklar.xlsx" class="text-purple" download
                           v-text="$t(locale.downloadExcel)"></a>
                      </div>
                    </div>
                  </b-card-text>
                </b-card>

                <b-button
                    block
                    :disabled="processing"
                    variant="primary"
                    @click="performArticleUpload"
                    v-text="
                    processing
                      ? $t('Message.loading') + '...'
                      : $t(locale.upload)
                  "
                />
              </b-col>
              <b-col cols="12" lg="4"></b-col>
            </b-row>
          </ValidationObserver>
        </b-container>
      </b-card-text>

      <b-card-title v-text="$t(locale.exportLogDetail)"/>

      <b-card-text>
        <b-table
            responsive
            show-empty
            :busy="loading"
            class="scrol-area"
            :items="items"
            :fields="fields"
        >
          <template #head(eventId)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.field.label)"/>
            </div>
          </template>
          <template #head(filename)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.field.label)"/>
            </div>
          </template>

          <template #head(exportTime)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.field.label)"/>
            </div>
          </template>

          <template #head(status)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.field.label)"/>
            </div>
          </template>

          <template #head(message)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.field.label)"/>
            </div>
          </template>

          <template #head(action)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.field.label)"/>
            </div>
          </template>

          <template #cell(eventId)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
             <span
                 class="text-center"
                 v-text="$t(data.item.event_id)"
             />
            </div>
          </template>

          <template #cell(filename)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.item.file_name)"/>
            </div>
          </template>

          <template #cell(exportTime)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span
                  class="text-center"
                  v-text="`${data.item.created_at.split('T')[0]}, ${data.item.created_at.split('T')[1].split('.')[0]}`"
              />
            </div>
          </template>

          <template #cell(status)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span v-if="data.item.finished_without_errors">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-green-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                  <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                  />
                </svg>
              </span>
              <span v-else>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-red-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                >
                  <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </span>
            </div>
          </template>

          <template #cell(message)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span
                  class="text-center" v-if="GET_LOCALE === 'sv'"
              > {{
                  getArticleStats(data.item.sync_summary, 'articles_created_in_accounting')
                }} / {{ getArticleStats(data.item.sync_summary, 'articles_in_file') }} artiklar skapade i bokföringssystemet, {{
                  getArticleStats(data.item.sync_summary, 'articles_existing_in_accounting')
                }} artiklar existerar redan i bokföringssystemet</span>
              <span class="text-center" v-else>{{
                  getArticleStats(data.item.sync_summary, 'articles_created_in_accounting')
                }} / {{
                  getArticleStats(data.item.sync_summary, 'articles_in_file')
                }} article(s) created in accounting, {{
                  getArticleStats(data.item.sync_summary, 'articles_existing_in_accounting')
                }} article(s) existing in accounting. </span>
            </div>
          </template>

          <template #cell(action)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
               <span class="cursor-pointer mr-0.5" v-on:click="reSyncFile(data.item)"
                     v-if="data.item.finished_without_errors === false">
                    <mdicon
                        :width="20"
                        :height="20"
                        class="text-secondaryColor h-5 w-5 "
                        name="refresh"
                    />
                </span>
              <span class="cursor-pointer" @click="openModal(data.item)"
              >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-blue-800"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                >
                  <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  />
                </svg>
              </span>

              <!-- <span class="cursor-pointer" @click="downloadReceipt(data.item.uuid)">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-blue-800" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10" />
                                </svg>
                            </span> -->
            </div>
          </template>

          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>{{ $t('Message.loading') }}...</strong>
            </div>
          </template>
        </b-table>

        <!-- <div
                    class="d-flex align-items-end flex-sm-column justify-content-between justify-content-sm-end"
                >
                    <b-pagination
                        v-model="pagination.currentPage"
                        :total-rows="pagination.totalProducts"
                        :per-page="queryParams.limit"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0 order-2 order-sm-1"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div> -->
      </b-card-text>
    </b-card>

    <CustomNoHeaderModalVue
        v-if="openReceiptModal"
        width="w-11/12 md:w-1/2"
        :title="''"
        @handleClose="openReceiptModal = false"
        :close="!true"
    >
      <div class="flex flex-col" slot="header">
        <div
            class="flex flex-col md:flex-row md:justify-between md:items-center gap-5 border-b p-1"
        >
          <div class="flex flex-col">
            <h2
                class="text-xl md:text-2xl font-semibold font-poppins text-gray-900"
            >
              {{ $t('exportCard.logInformation') }}
            </h2>
          </div>
        </div>
      </div>
      <div class="flex flex-col" slot="body">
        <div class="flex flex-col mb-1">
          <h1 class="font-semibold text-lg">{{ $t('exportCard.fileNameType') }}:</h1>
          <span
          >{{ selectedData.file_name }} |
            {{ selectedData.file_type }}</span
          >
        </div>
        <div class="flex flex-col mb-1">
          <h1 class="font-semibold text-lg">{{ $t('exportCard.dateTime') }}:</h1>
          <span>{{ this.selectedDataCreatedAt }}</span>
        </div>
        <div class="flex flex-col mb-1">
          <h1 class="font-semibold text-lg">Status:</h1>
          <span class="flex">
            <span
                v-if="selectedData.status.hasOwnProperty('swe_status') && selectedData.status.hasOwnProperty('eng_status')"
                class="mr-1">
              <span v-if="GET_LOCALE === 'sv'">{{ selectedData.status.swe_status }}</span>
              <span v-if="GET_LOCALE === 'en'"> {{ selectedData.status.eng_status }}</span>
            </span>
            <span><span v-if="selectedData.finished_without_errors">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 text-green-500"
                  viewBox="0 0 20 20"
                  fill="currentColor"
              >
                <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                />
              </svg>
            </span>
            <span v-else>
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 text-red-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
              >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </span></span>
          </span>
        </div>
        <div class="flex flex-col mb-1">
          <h1 class="font-semibold text-lg">{{ $t('exportCard.message') }}:</h1>
        </div>
        <div class="flex">
          <table class="table">
            <thead>
            <tr>
              <th>{{ $t('exportCard.type') }}</th>
              <th>{{ $t('exportCard.message') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) in combineMessage(selectedData)" :key="i">
              <td>
                <span class="text-red-500 font-semibold" v-if="item.type === 'Error'">{{ item.type }}</span>
                <span class="text-blue-500 font-semibold" v-else>{{ item.type }}</span>
              </td>
              <td>
                <span v-if="GET_LOCALE === 'sv'">{{ item.sv }}</span>
                <span v-else>{{ item.en }}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </CustomNoHeaderModalVue>

    <CustomNoHeaderModalVue
        v-if="openArticleCreate"
        width="w-11/12 md:w-1/2"
        :title="''"
        @handleClose="openArticleCreate = false"
        :backdropOff="true"
        :close="!true"
    >
      <div class="flex flex-col" slot="header">
        <div
            class="flex flex-col md:flex-row md:justify-between md:items-center gap-5 border-b p-1"
        >
          <div class="flex flex-col">
            <h3
                class="text-xl md:text-xl font-semibold font-poppins text-theme-sidebar"
            >
              {{ $t('exportCard.cannotBeFound') }}
            </h3>
          </div>
        </div>
      </div>
      <div class="flex flex-col h-4/6" slot="body">
        <div class="article-action-height">
          <div class="grid grid-cols-3 gap-3">
            <div class="col-span-1"><span class="font-semibold text-sm">{{ $t('exportCard.articleDescription') }}</span>
            </div>
            <div class="col-span-1"><span class="font-semibold text-sm">{{ $t('exportCard.existInAccounting') }}</span>
            </div>
            <div class="col-span-1"><span class="font-semibold text-sm">{{
                $t('exportCard.DoesNotexistInAccounting')
              }}</span></div>
          </div>
          <div class="lg:mt-3 " v-for="(item,i) in articleMainForm">
            <div class="grid grid-cols-1 md:grid-cols-3 gap-3 ">
              <div class="flex flex-col">
              <span class="flex">
                <span class="mr-1 cursor-pointer" :class="{'rotate-180':item.show === true}"
                      v-on:click="openArticleAccordion(i,item.show)"><svg width="10" height="10"
                                                                          viewBox="0 0 14 14" fill="none"
                                                                          xmlns="http://www.w3.org/2000/svg"><path
                    d="M0 6.4L0.7 7.1L6.7 1.1L12.7 7.1L13.4 6.4L7.1 0H6.4L0 6.4ZM0 12.4L0.7 13.1L6.7 7.1L12.7 13.1L13.4 12.4L7.1 6H6.4L0 12.4Z"
                    fill="black"/>
              </svg>
              </span>
              <span class="font-semibold text-sm">Spelavg alla dagar {{ item.article_number }}</span>
              </span>
              </div>
              <div class="flex flex-col">
                <b-form-group class="center-item">
                  <b-form-radio
                      :name="'radio-exist-' + i"
                      class="c-mb-2"
                      :value="true"
                      v-model="item.exist"
                  />
                </b-form-group>
              </div>
              <div class="flex flex-col justify-center">
                <b-form-group class="center-item">
                  <b-form-radio
                      :name="'radio-exist-'+i"
                      class="c-mb-2"
                      v-model="item.exist"
                      :value="false"
                  />
                </b-form-group>
              </div>
            </div>
            <div class="flex flex-col" v-if="item.show">
              <span class="flex items-center ml-1 mb-2">
                    <div class="w-full md:w-1/3">
                      <span class="text-blue-dark text-sm"
                            v-if="item.show">{{ $t('exportCard.articleTab.existArticleTitle') }}</span>
                      <span class="text-blue-dark text-sm" v-else>{{ $t('ArticleSegment.articleNumber') }}</span>
                    </div>
                    <div class="w-full md:w-1/2">
                      <input
                          v-model="item.article_number"
                          type="text"
                          class="focus:outline-none h-8 flex custom-border border-blue-300 rounded c-p-2"
                      />
                    </div>
                  </span>
              <span v-if="item.exist === false" class="flex items-center ml-1 mb-1">
                    <div class="w-full md:w-1/3">
                      <span class="text-blue-dark text-sm"
                      >Sales account SE</span
                      >
                    </div>
                    <div class="w-full md:w-1/2">
                      <input
                          v-model="item.se_sale_account"
                          type="text"
                          class="focus:outline-none w-1/2 h-8 flex custom-border border-blue-300 rounded c-p-2"
                      />
                    </div>
                  </span>
              <span v-if="item.exist === false" class="flex items-center ml-1 mb-1">
                    <div class="w-full md:w-1/3">
                      <span class="text-blue-dark text-sm"
                      >Sales account EU</span
                      >
                    </div>
                    <div class="w-full md:w-1/2">
                      <input
                          v-model="item.eu_sale_account"
                          type="text"
                          class="focus:outline-none w-1/2 h-8 flex custom-border border-blue-300 rounded c-p-2"
                      />
                    </div>
                  </span>
              <span v-if="item.exist === false" class="flex items-center ml-1 mb-1">
                    <div class="w-full md:w-1/3">
                      <span class="text-blue-dark text-sm"
                      >Sales account EU (VAT)</span
                      >
                    </div>
                    <div class="w-full md:w-1/2">
                      <input
                          v-model="item.eu_vat_sale_account"

                          type="text"
                          class="focus:outline-none w-1/2 h-8 flex custom-border border-blue-300 rounded c-p-2"
                      />
                    </div>
                  </span>
              <span v-if="item.exist === false" class="flex items-center ml-1 mb-1">
                    <div class="w-full md:w-1/3">
                      <span class="text-blue-dark text-sm"
                      >Sales account Export</span
                      >
                    </div>
                    <div class="w-full md:w-1/2">
                      <input
                          v-model="item.wo_sale_account"
                          type="text"
                          class="focus:outline-none w-1/2 h-8 flex custom-border border-blue-300 rounded c-p-2"
                      />
                    </div>
                  </span>
            </div>
          </div>
        </div>

        <div class="flex items-center justify-end gap-4 mt-1">
          <b-button
              @click="openArticleCreate = false"
              variant="outline-danger"
              v-text=" $t('Message.cancel')"
          />
          <b-button
              variant="primary"
              @click="processAccountingArticles"
              v-text="processingArticle ? $t('Message.loading') : 'Confirm'"
          />
        </div>
      </div>
    </CustomNoHeaderModalVue>
  </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from "vee-validate";
import vSelect from "vue-select";
import DatePicker from "vue2-datepicker";
import useAppConfig from "@core/app-config/useAppConfig";
import {mapGetters} from "vuex"
import CustomNoHeaderModalVue from "@/@core/customComponent/CustomNoHeaderModal.vue";

import mdAccessDenied from "@core/components/permission/mdAccessDenied";

export default {
  name: "invoiceExport",
  components: {
    vSelect,
    DatePicker,
    ValidationProvider,
    ValidationObserver,
    CustomNoHeaderModalVue, mdAccessDenied
  },
  data() {
    return {
      openReceiptModal: false,
      openArticleCreate: false,
      accessDenied: false,
      loading: false,
      selectedData: {},
      processingArticle: false,
      config: useAppConfig(),
      processing: false,
      paymentTermResults: [],
      items: [],
      queryParams: {
        limit: 30,
      },
      articleMainForm: [],
      articleSelectedData: {},
      accountingForm: {
        show: false,
        exist: null,
        article_description: "",
        exists: {
          show: false,
          article_number: "",
        },
        notFound: {
          show: false,
          article_number: "",
          se_sale_account: "",
          eu_sale_account: "",
          eu_vat_sale_account: "",
          wo_sale_account: "",
        },
      },
      article_to_create: ["article145212", "art14520000"],
      pagination: {
        currentPage: 1,
        totalProducts: 0,
      },
      invoiceForm: [
        // {
        //     col: 12,
        //     lg: 6,
        //     label: 'dashboardCard.table.invoiceDateHead',
        //     type: 'date',
        //     value: '',
        //     rules : 'required',
        //     fieldValue : 'invoice_date',
        // },
        // {
        //     col: 12,
        //     lg: 6,
        //     label: 'none',
        //     type: 'input',
        //     value: '',
        //     rules : '',
        //     fieldValue : '',
        // },
        // {
        //     col: 12,
        //     lg: 12,
        //     label: 'InvoiceCardCustomerEdit.paymentTerms',
        //     type: 'select',
        //     value: '',
        //     rules : 'required',
        //     fieldValue : 'terms_of_payment',
        // },
        // {
        //     col: 12,
        //     lg: 6,
        //     label: 'exportCard.verboseFileName',
        //     type: 'input',
        //     option: [],
        //     value: '',
        //     rules : '',
        //     fieldValue : 'verbose_file_name',
        // },
        // {
        //     col: 12,
        //     lg: 12,
        //     label: 'InvoiceCardCustomerEdit.invoiceText',
        //     type: 'textarea',
        //     value: '',
        //     rules : 'required',
        //     fieldValue : 'invoice_text',
        // },
      ],
      fields: [
        {
          key: "eventId",
          label: "exportCard.table.eventId",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 10px",
            width: "90px",
          },
        },
        {
          key: "filename",
          label: "exportCard.table.fileName",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "70px",
          },
        },
        {
          key: "exportTime",
          label: "exportCard.table.exportTime",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "90px",
          },
        },
        {
          key: "status",
          label: "Message.tableHeader.status",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "70px",
          },
        },
        {
          key: "message",
          label: "Notification.message",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "250px",
          },
        },
        {
          key: "action",
          label: "receiptSendCard.action",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "50px",
          },
        },
      ],
      locale: {
        upload: "upload",
        uploadInvoiceTitle: "exportCard.uploadInvoiceTitle",
        alertPermited: "exportCard.alertPermited",
        downloadExcel: "exportCard.downloadExcel",
        messageTitle: "exportCard.articleTitle",
        messageFooter: "exportCard.articleFooter",
        exportLogDetail: "exportCard.exportLogDetails",
      },
      file: "",
      newImage: "",
    };
  },
  computed: {
    message_event() {
      if (this.GET_LOCALE === "") {
        return this.swedishLang ? "swe_events" : "eng_events";
      } else return this.GET_LOCALE === "sv" ? "swe_events" : "eng_events";
    },
    messageList() {
      return this.$t("exportCard.articleList");
    },
    message() {
      return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message'
    },
    locale_message() {
      return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message'
    },
    features() {
      if (this.GET_LOCALE === "") {
        return this.swedishLang ? "features" : "features";
      } else return this.GET_LOCALE === "sv" ? "features" : "features";
    },
    getLang() {
      return this.config.lang.value;
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },
  mounted() {
    this.paymentTerms();
    this.getUploadList();
  },
  methods: {
    reSyncFile(item) {
      this.$useJwt
          .uploads({URL: `/${item.uuid}/`, method: "put"})
          .then((res) => {
            this.getUploadList();
            this.popupMsg(
                "Pending",
                res.data[this.locale_message],
                "CheckIcon",
                "success"
            );
            // open modal if we have article to create
            if (res.data.code === "40050") {
              // code signify that an action is needed to be perform on the uploaded article that this being uploaded by the user
              if (
                  res.data.articles_to_create &&
                  res.data.articles_to_create.length > 0
              ) {
                this.articleSelectedData = res.data.data;
                const array = [];
                res.data.articles_to_create.forEach((element) => {
                  const obj = {
                    name: element,
                    show: false,
                    article_description: element,
                    article_number: "",
                    se_sale_account: "",
                    eu_sale_account: "",
                    eu_vat_sale_account: "",
                    wo_sale_account: "",
                    exist: false,
                  };
                  array.push(obj);
                });
                this.articleMainForm = array
                this.openArticleCreate = true
              }
            }

          })
          .catch((err) => {
            this.processing = false;
            window.Bus.$emit('output-custom-error', err)
          });
    },
    openArticleAccordion(index, status) {
      this.articleMainForm[index].show = !status
    },
    getArticleStats(data, value) {
      if (data.length > 0) {
        let d = data[0]
        return d[value]
      }
    },
    async paymentTerms() {
      const {data} = await this.$useJwt.getAllRegisters("termsofpayment");
      this.paymentTermResults = data.results;
    },
    getUploadList() {
      this.loading = true;
      this.accessDenied = false
      this.$useJwt
          .uploads({URL: "?file_type=articles", method: "get"})
          .then((res) => {
            this.loading = false;
            this.items = res.data.data;
          })
          .catch((err) => {
            this.loading = false
            if (err.response.status === 403) {
              this.accessDenied = true
            } else {
              this.accessDenied = false
            }
          });
    },
    renderMessage(data) {
      if (data.length > 0) {
        return data[0][this.message_event] &&
        data[0][this.message_event].length > 0
            ? data[0][this.message_event][0]
            : "";
      } else {
        return "";
      }
    },
    renderSyncMessage(data) {
      if (data.length > 0) {
        return `${data[0].error_message} | Error Code: ${data[0].error_code}`;
      }
      return "";
    },
    inputImage() {
      const acceptedType = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ];
      const fileType = this.$refs.imageInput.files[0].type.split("/")[1];

      const files = this.$refs.imageInput.files[0];

      if (acceptedType.includes(fileType)) {
        this.file = files;
        // this.formData.append('image', files);
        var reader = new FileReader();

        reader.addEventListener("load", () => {
          this.newImage = reader.result;
        });

        reader.readAsDataURL(files);
      } else {
        this.popupMsg(
            this.$t("Message.Failed"),
            "Invalid file format",
            "AlertTriangleIcon",
            "danger"
        );
      }
    },
    uploadImage() {
      this.$refs.imageInput.click();
    },
    performArticleUpload() {
      this.$refs.form.validate().then((res) => {
        if (res) {
          if (this.file) {
            const formData = new FormData();
            this.invoiceForm.map((el) => {
              if (el.fieldValue === "terms_of_payment") {
                formData.append(el.fieldValue, parseInt(el.value.id));
              } else {
                formData.append(el.fieldValue, el.value);
              }
            });
            formData.append("file", this.file);
            formData.append("file_type", "articles");
            this.processing = true;
            this.$useJwt
                .uploads({URL: "/", method: "post"}, formData)
                .then((res) => {
                  this.getUploadList();
                  this.processing = false;
                  this.resetForm();
                  this.$refs.form.reset();
                  this.popupMsg(
                      "Pending",
                      res.data[this.locale_message],
                      "CheckIcon",
                      "warning"
                  );
                  // open modal if we have article to create
                  if (res.data.code === "40050") {
                    // code signify that an action is needed to be perform on the uploaded article that this being uploaded by the user
                    if (
                        res.data.articles_to_create &&
                        res.data.articles_to_create.length > 0
                    ) {
                      this.articleSelectedData = res.data.data;
                      const array = [];
                      res.data.articles_to_create.forEach((element) => {
                        const obj = {
                          name: element,
                          show: false,
                          article_description: element,
                          article_number: element,
                          se_sale_account: "",
                          eu_sale_account: "",
                          eu_vat_sale_account: "",
                          wo_sale_account: "",
                          exist: false,
                        };
                        array.push(obj);
                      });
                      this.articleMainForm = array
                      this.openArticleCreate = true
                    }
                  }

                })
                .catch((err) => {
                  this.processing = false;
                  window.Bus.$emit('output-custom-error', err)
                });
          } else {
            this.popupMsg(
                this.$t("Message.Failed"),
                "Vänligen ladda upp en artikelfil",
                "AlertTriangleIcon",
                "danger"
            );
          }
        }
      });
    },
    processAccountingArticles() {
      const payload = {existing_articles: [], new_articles: []};
      if (!this.validateArtifleForm()) {
        this.articleMainForm.map((el) => {
          const obj = {};
          if (el.exist) {
            obj["article_number"] = el.article_number;
            obj["article_description"] = el.article_description;
            payload.existing_articles.push(obj);
          } else {
            obj["article_description"] = el.article_description;
            obj["article_number"] = el.article_number;
            obj["se_sale_account"] = el.se_sale_account;
            obj["eu_sale_account"] = el.eu_sale_account;
            obj["eu_vat_sale_account"] = el.eu_vat_sale_account;
            obj["wo_sale_account"] = el.wo_sale_account;
            payload.new_articles.push(obj);
          }
        });
        this.processingArticle = true;
        this.$useJwt
            .uploads(
                {
                  URL: `/article_processor/${this.articleSelectedData.uuid}/`,
                  method: "put",
                },
                payload
            )
            .then((res) => {
              this.getUploadList();
              this.openArticleCreate = false;
              this.processingArticle = false;
              this.articleSelectedData = {};
              this.articleMainForm = [];
              this.$refs.form.reset();
              this.popupMsg(
                  "Pending",
                  res.data[this.message],
                  "CheckIcon",
                  "warning"
              );
            })
            .catch((err) => {
              this.processingArticle = false;
              this.popupMsg(
                  this.$t("Message.Failed"),
                  err.response.data[this.message],
                  "AlertTriangleIcon",
                  "danger"
              );
            });
      } else {
        this.popupMsg(
            this.$t("Message.Failed"),
            "Article fields contains invalid information",
            "AlertTriangleIcon",
            "danger"
        );
      }
    },
    validateArtifleForm() {
      let error = false;
      if (this.articleMainForm.length > 0) {
        this.articleMainForm.map((el) => {
          // if (el.article_description === "") {
          //   error = true;
          // }
          if (el.exist) {
            if (el.article_number === "") {
              error = true;
            }
          } else {
            if (
                el.article_number === "" ||
                el.se_sale_account === "" ||
                el.eu_sale_account === "" ||
                el.eu_vat_sale_account === "" ||
                el.wo_sale_account === ""
            ) {
              error = true;
            }
          }
        });
      } else {
        error = true;
      }
      return error;
    },
    openModal(item) {
      this.selectedData = item;
      this.selectedDataCreatedAt = `${this.selectedData.created_at.split('T')[0]}, ${this.selectedData.created_at.split('T')[1].split('.')[0]}`
      this.openReceiptModal = true;
    },
    resetForm() {
      this.invoiceForm.map((el) => {
        el.value = "";
      });
      this.file = "";
    },
    combineMessage(selectData) {
      let sync_error = selectData.sync_errors
      let sync_summary = selectData.sync_summary

      let messages = []
      sync_error.map(item => {
        messages.push({'type': 'Error', 'sv': item.swe_error_message, 'en': item.eng_error_message})
      })
      sync_summary.map(item => {
        if (item.swe_success_messages && item.eng_success_messages) {
          for (let i = 0; i < item.swe_success_messages.length; i++) {
            messages.push({'type': 'Info', 'sv': item.swe_success_messages[i], 'en': item.eng_success_messages[i]})
          }
        }
      })
      return messages
    }
  },
};
</script>

<style scoped lang="scss">
.resource-selector {
  width: 100%;
}

.center-item {
  margin: auto;
  display: table;
}

.article-action-height {
  height: 350px;
  overflow-y: scroll;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/datepicker.scss";
</style>
