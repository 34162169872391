<template>
  <div>

    <div class="grid grid-cols-1 gap-1 lg:grid-cols-2 gap-2">
      <LittleTabs
          :tabs="tabs"
          :switchTabs="switchTabs"
          containerStyle="justify-content-start"
      />
      <div class="sm:text-left lg:text-right">
        <button type="button" class="btn btn-primary" v-on:click="onOpenModal">Guide</button>
      </div>
    </div>
    <KeepAlive>
      <transition name="zoom-fade" mode="out-in">
        <component class="mt-2" :is="currentComponent"/>
      </transition>
    </KeepAlive>
    <CustomNoHeaderModalVue v-if="openModal" width="w-full md:w-3/4" :title="''" @handleClose="openModal = false"
                            :close="!true">

      <div class="flex flex-col" slot="body" id="export-content">
        <div id="pdfvuer">
          <!--<pdf-frame/>-->
          <iframe src="../../exports/export_guide.pdf#toolbar=0" id="exportPdf" style="height: 900px;width: 100%"/>
        </div>
      </div>
    </CustomNoHeaderModalVue>
  </div>
</template>

<script>
import invoiceExport from '@/@core/components/golfrelated/export/invoiceExport.vue';
import memberExport from '@/@core/components/golfrelated/export/memberExport.vue';
import articleExport from '@/@core/components/golfrelated/export/articleExport.vue';
import CustomNoHeaderModalVue from '@/@core/customComponent/CustomNoHeaderModal.vue';
import pdfFrame from "@/views/newViews/golfRelated/pdfFrame";
import $ from 'jquery'

window.$ = $
export default {
  name: 'exports',
  components: {
    invoiceExport,
    memberExport,
    articleExport,
    CustomNoHeaderModalVue,
    pdfFrame
  },
  data() {
    return {
      page: 1,
      numPages: 0,
      pdfdata: undefined,
      errors: [],
      scale: 'page-width',
      currentComponent: 'invoiceExport',
      openModal: false,
      src: 1,
      // numPages: undefined,
      tabs: [
        {
          label: 'exportCard.invoiceExport',
          component: 'invoiceExport',
          active: true,
        },
        {
          label: 'exportCard.memberExport',
          component: 'memberExport',
          active: false,
        },
        {
          label: 'exportCard.articleExport',
          component: 'articleExport',
          active: false,
        },
      ],
    };
  },
  computed: {},
  watch: {},
  methods: {
    onOpenModal: function () {
      this.openModal = !this.openModal

    },

    switchTabs(tabIndex) {
      this.tabs.find(item => item.active == true).active = false;

      this.currentComponent = this.tabs[tabIndex].component;

      this.tabs[tabIndex].active = true;
    },
    getUserSubscriptions() {
      this.$useJwt.subscription().catch(err => {
        if (err.response.status === 403) {
          window.Bus.$emit('trigger-access-denied', {'data': err.response.data})
        }
      })
    },
    querySelectorAllInIframes(selector) {
      let elements = [];

      const recurse = (contentWindow = window) => {

        const iframes = contentWindow.document.body.querySelectorAll('iframe');
        iframes.forEach(iframe => recurse(iframe.contentWindow));

        elements = elements.concat(contentWindow.document.body.querySelectorAll(selector));
      }

      recurse();

      return elements;
    }

  },

  mounted() {
    this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', false)
    if (this.$route.query.hasOwnProperty('target')) {
      this.switchTabs(1)
    }

  },
};
</script>

<!--<style src="pdfvuer/dist/pdfvuer.css"></style>-->
<style lang="css" scoped>
#buttons {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

#toolbar {
  display: none !important;
}

/* Page content */
.content {
  padding: 16px;
}
</style>
