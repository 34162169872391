<template>
  <div>
    <b-card :class="{'md-access-denied-height': accessDenied === true}">
      <md-access-denied :is-access-denied="accessDenied" v-if="accessDenied === true" class="mt-40"/>
      <div class="flex mb-2">
        <div class="flex gap-3 bg-theme-teal rounded-md c-p-2">
          <span v-if="hidden_fields.generate_invoice === false"
                @click="tab = 1"
                :class="tab === 1 ? 'text-gray-800 bg-white' : 'text-white'"
                class="flex cursor-pointer c-p-1 rounded-md"
          >{{ $t('exportCard.generateInvoice') }}</span
          >
          <span v-if="hidden_fields.generate_contract === false"
                @click="tab = 2"
                :class="tab === 2 ? 'text-gray-800 bg-white' : 'text-white'"
                class="flex cursor-pointer c-p-1 rounded-md"
          >{{ $t('exportCard.generateContract') }}</span
          >
        </div>
      </div>
      <b-card-text>
        <b-container v-if="tab === 1 && hidden_fields.generate_invoice === false">
          <ValidationObserver ref="form">
            <b-row>
              <b-col cols="12" lg="4">
                <b-row>
                  <b-col
                      v-for="(form, index) in invoiceForm"
                      :key="index"
                      :cols="form.col"
                      :lg="form.lg"
                  >
                    <ValidationProvider
                        #default="{ errors }"
                        :rules="form.rules"
                        v-if="form.label !== 'none'"
                        :name="$t(form.label)"
                    >
                      <b-form-group :label="$t(form.label)" v-if="form.hidden === false">
                        <date-picker
                            v-if="form.type == 'date'"
                            valueType="format"
                            v-model="form.value"
                            class="w-100"
                            prefix-class="xmx"
                            :placeholder="$t(form.label)"
                        />

                        <b-form-input
                            v-else-if="form.type == 'input'"
                            :placeholder="$t(form.label)"
                            v-model="form.value"
                            :maxlength="form.maxlength"
                        />

                        <v-select
                            v-else-if="form.type === 'select'"
                            v-model="form.label_value"
                            label="label"
                            @option:selected="computePaymentTermsSelect($event,'invoice', index)"
                            :options="paymentTermResults"
                            :placeholder="$t(form.label)"
                            class="resource-selector d-inline-block"
                        />

                        <b-form-textarea
                            v-else-if="form.type == 'textarea'"
                            v-model="form.value"
                            :placeholder="$t(form.label)"
                            rows="3"
                        />

                        <small class="text-danger" v-text="errors[0]"/>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
              </b-col>

              <b-col cols="12" lg="4" v-if="hidden_fields.file === false">
                <b-card border-variant="secondary">
                  <b-card-text>
                    <div class="d-flex flex-column bigGap">
                      <p class="mb-0" v-text="$t(locale.uploadInvoiceTitle)"/>

                      <span
                          v-if="file"
                          class="font-semibold text-xl text-green-500"
                      >
                        Fil vald
                        <span>
                          <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5 text-green-500"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                          >
                            <path
                                fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clip-rule="evenodd"
                            />
                          </svg>
                        </span>
                      </span>

                      <input
                          hidden
                          type="file"
                          @change="inputImage"
                          ref="imageInput"
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      />

                      <b-button
                          class="mt-2"
                          v-ripple.400
                          @click="uploadImage"
                          variant="flat-primary"
                          v-text="'Ladda upp fil'"
                      />

                      <div class="mt-3">
                        <p class="" v-text="`* ${$t(locale.alertPermited)}`"/>

                        <a href="../../exports/fakturor.xlsx" class="text-purple" download
                           v-text="$t(locale.downloadExcel)">
                        </a>
                      </div>
                    </div>
                  </b-card-text>
                </b-card>

                <b-button
                    block
                    :disabled="processing"
                    variant="primary"
                    @click="performInvoiceUpload"
                    v-text="
                    processing
                      ? $t('Message.loading') + '...'
                      : $t(locale.upload)
                  "
                />
              </b-col>
              <b-col cols="12" lg="4">
                <p class="" v-text="$t(locale.messageTitle)"/>

                <ul class="mb-1 pl-2">
                  <li
                      v-for="(message, index) in messageList"
                      :key="index"
                      style="list-style-type: disc"
                      v-text="message"
                  />
                </ul>

                <p class="" v-text="$t(locale.messageFooter)"/>
              </b-col>
            </b-row>
          </ValidationObserver>
        </b-container>

        <b-container v-if="tab === 2 && hidden_fields.generate_contract === false">
          <ValidationObserver ref="form">
            <b-row>
              <b-col cols="12" lg="6">
                <b-row>
                  <b-col
                      v-for="(form, index) in contractForm"
                      :key="index"
                      :cols="form.col"
                      :lg="form.lg"
                  >
                    <ValidationProvider
                        #default="{ errors }"
                        :rules="form.rules"
                        v-if="form.label !== 'none'"
                        :name="$t(form.label)"
                    >
                      <b-form-group :label="$t(form.label)" v-if="form.hidden === false">
                        <date-picker
                            v-if="form.type == 'date'"
                            valueType="format"
                            v-model="form.value"
                            class="w-100"
                            prefix-class="xmx"
                            :placeholder="$t(form.label)"
                        />

                        <b-form-input
                            v-else-if="form.type == 'input' && form.mode === 'text'"
                            :placeholder="$t(form.label)"
                            v-model="form.value"
                        />
                        <b-form-input
                            v-else-if="form.type == 'input' && form.mode === 'number'"
                            :type="form.mode"
                            min="0"
                            :placeholder="$t(form.label)"
                            v-model="form.value"
                        />

                        <v-select
                            v-else-if="form.type === 'select'"
                            v-model="form.label_value"
                            label="label"
                            :options="paymentTermResults"
                            @option:selected="computePaymentTermsSelect($event,'contract', index)"
                            :placeholder="$t(form.label)"
                            class="resource-selector d-inline-block"
                        />

                        <b-form-textarea
                            v-else-if="form.type == 'textarea'"
                            v-model="form.value"
                            :placeholder="$t(form.label)"
                            rows="3"
                        />
                        <b-form-group v-else-if="form.type === 'radio'">
                          <b-form-radio
                              v-model="form.value"
                              name="radio-1"
                              :value="true">
                            <span>{{ $t('exportCard.yes') }}</span>
                          </b-form-radio>
                          <b-form-radio
                              name="radio-1"
                              v-model="form.value"
                              :value="false">
                            <span>{{ $t('exportCard.no')}}</span>
                          </b-form-radio>
                        </b-form-group>
                        <small class="text-danger" v-text="errors[0]"/>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" lg="1"></b-col>
              <b-col cols="12" lg="5" v-if="hidden_fields.file === false">
                <b-card border-variant="secondary">
                  <b-card-text>
                    <div class="d-flex flex-column bigGap">
                      <p class="mb-0" v-text="$t(locale.uploadInvoiceTitle)"/>

                      <span
                          v-if="file"
                          class="font-semibold text-xl text-green-500"
                      >
                        Fil vald
                        <span>
                          <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5 text-green-500"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                          >
                            <path
                                fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clip-rule="evenodd"
                            />
                          </svg>
                        </span>
                      </span>

                      <input
                          hidden
                          type="file"
                          @change="inputImage"
                          ref="imageInput"
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      />

                      <b-button
                          class="mt-2"
                          v-ripple.400
                          @click="uploadImage"
                          variant="flat-primary"
                          v-text="'Ladda upp fil'"
                      />

                      <div class="mt-3">
                        <p class="" v-text="`* ${$t(locale.alertPermited)}`"/>

                        <a href="../../exports/fakturor.xlsx" class="text-purple" download
                           v-text="$t(locale.downloadExcel)">
                        </a>

                        <!-- </a> -->
                      </div>
                    </div>
                  </b-card-text>
                </b-card>

                <b-button
                    block
                    :disabled="processing"
                    variant="primary"
                    @click="performInvoiceUpload('contracts')"
                    v-text="
                    processing
                      ? $t('Message.loading') + '...'
                      : $t(locale.upload)
                  "
                />
              </b-col>
            </b-row>
          </ValidationObserver>
        </b-container>
      </b-card-text>

      <b-card-title v-text="$t(locale.exportLogDetail)" v-if="tab === 1"/>
      <b-card-title v-text="$t(locale.contractExportLogDetail)" v-if="tab === 2"/>

      <b-card-text>
        <b-table v-if="tab === 1"
                 responsive
                 show-empty
                 :busy="loading"
                 class="scrol-area"
                 :items="items"
                 :fields="fields"
        >
          <template #head(eventId)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.field.label)"/>
            </div>
          </template>
          <template #head(filename)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.field.label)"/>
            </div>
          </template>

          <template #head(exportTime)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.field.label)"/>
            </div>
          </template>

          <template #head(status)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.field.label)"/>
            </div>
          </template>

          <template #head(message)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.field.label)"/>
            </div>
          </template>

          <template #head(action)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.field.label)"/>
            </div>
          </template>

          <template #cell(eventId)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
               <span
                   class="text-center"
                   v-text="$t(data.item.event_id)"
               />
            </div>
          </template>

          <template #cell(filename)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.item.file_name)"/>
            </div>
          </template>

          <template #cell(exportTime)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span
                  class="text-center"
                  v-text="`${data.item.created_at.split('T')[0]}, ${data.item.created_at.split('T')[1].split('.')[0]}`"
              />
            </div>
          </template>

          <template #cell(status)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span v-if="data.item.finished_without_errors">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-green-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                  <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                  />
                </svg>
              </span>
              <span v-else>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-red-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                >
                  <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </span>
            </div>
          </template>

          <template #cell(message)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span
                  class="text-center" v-if="GET_LOCALE === 'sv'"
              > {{
                  getInvoiceStats(data.item.sync_summary, 'invoices_created_in_accounting')
                }} / {{ getInvoiceStats(data.item.sync_summary, 'invoices_in_file') }} fakturor skapade i bokföringssystemet, {{
                  getInvoiceStats(data.item.sync_summary, 'invoices_existing_in_accounting')
                }}  fakturor existerar redan i bokföringssystemet</span>
              <span class="text-center" v-else>{{
                  getInvoiceStats(data.item.sync_summary, 'invoices_created_in_accounting')
                }} / {{ getInvoiceStats(data.item.sync_summary, 'invoices_in_file') }} invoice(s) created in accounting, {{
                  getInvoiceStats(data.item.sync_summary, 'invoices_existing_in_accounting')
                }} invoice(s) existing in accounting. </span>
            </div>
          </template>

          <template #cell(action)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="cursor-pointer mr-0.5" v-on:click="reSyncFile(data.item)"
                    v-if="data.item.finished_without_errors === false">
                    <mdicon
                        :width="20"
                        :height="20"
                        class="text-secondaryColor h-5 w-5 "
                        name="refresh"
                    />
                </span>
              <span class="cursor-pointer" @click="openModal(data.item)">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-blue-800"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                >
                  <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  />
                </svg>
              </span>
              <!-- <span class="cursor-pointer" @click="downloadReceipt(data.item.uuid)">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-blue-800" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10" />
                                </svg>
                            </span> -->
            </div>
          </template>

          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>{{ $t('Message.loading') }}...</strong>
            </div>
          </template>
        </b-table>
        <b-table v-if="tab === 2"
                 responsive
                 show-empty
                 :busy="loading"
                 class="scrol-area"
                 :items="contract_items"
                 :fields="fields"
        >
          <template #head(eventId)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.field.label)"/>
            </div>
          </template>
          <template #head(filename)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.field.label)"/>
            </div>
          </template>

          <template #head(exportTime)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.field.label)"/>
            </div>
          </template>

          <template #head(status)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.field.label)"/>
            </div>
          </template>

          <template #head(message)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.field.label)"/>
            </div>
          </template>

          <template #head(action)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.field.label)"/>
            </div>
          </template>

          <template #cell(eventId)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
               <span
                   class="text-center"
                   v-text="$t(data.item.event_id)"
               />
            </div>
          </template>

          <template #cell(filename)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="text-center" v-text="$t(data.item.file_name)"/>
            </div>
          </template>

          <template #cell(exportTime)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span
                  class="text-center"
                  v-text="`${data.item.created_at.split('T')[0]}, ${data.item.created_at.split('T')[1].split('.')[0]}`"
              />
            </div>
          </template>

          <template #cell(status)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span v-if="data.item.finished_without_errors">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-green-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                  <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                  />
                </svg>
              </span>
              <span v-else>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-red-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                >
                  <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </span>
            </div>
          </template>

          <template #cell(message)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span
                  class="text-center" v-if="GET_LOCALE === 'sv'"
              > {{
                  getInvoiceStats(data.item.sync_summary, 'invoices_created_in_accounting')
                }} / {{ getInvoiceStats(data.item.sync_summary, 'invoices_in_file') }} Avtal skapade i bokföringssystemet, {{
                  getInvoiceStats(data.item.sync_summary, 'invoices_existing_in_accounting')
                }}  Avtal existerar redan i bokföringssystemet</span>
              <span class="text-center" v-else>{{
                  getInvoiceStats(data.item.sync_summary, 'invoices_created_in_accounting')
                }} / {{ getInvoiceStats(data.item.sync_summary, 'invoices_in_file') }} contracts created in accounting, {{
                  getInvoiceStats(data.item.sync_summary, 'invoices_existing_in_accounting')
                }} contracts existing in accounting. </span>
            </div>
          </template>

          <template #cell(action)="data">
            <div
                class="d-flex align-items-center justify-content-center"
                v-tableStyle="data.field.thStyle.width"
            >
              <span class="cursor-pointer mr-0.5" v-on:click="reSyncFile(data.item)"
                    v-if="data.item.finished_without_errors === false">
                    <mdicon
                        :width="20"
                        :height="20"
                        class="text-secondaryColor h-5 w-5 "
                        name="refresh"
                    />
                </span>
              <span class="cursor-pointer" @click="openModal(data.item)">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-blue-800"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                >
                  <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  />
                </svg>
              </span>
              <!-- <span class="cursor-pointer" @click="downloadReceipt(data.item.uuid)">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-blue-800" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10" />
                                </svg>
                            </span> -->
            </div>
          </template>

          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>{{ $t('Message.loading') }}...</strong>
            </div>
          </template>
        </b-table>

        <!-- <div
                    class="d-flex align-items-end flex-sm-column justify-content-between justify-content-sm-end"
                >
                    <b-pagination
                        v-model="pagination.currentPage"
                        :total-rows="pagination.totalProducts"
                        :per-page="queryParams.limit"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0 order-2 order-sm-1"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div> -->
      </b-card-text>
    </b-card>

    <CustomNoHeaderModalVue
        v-if="openReceiptModal"
        width="w-11/12 md:w-1/2"
        :title="''"
        @handleClose="openReceiptModal = false"
        :close="!true"
    >
      <div class="flex flex-col" slot="header">
        <div
            class="flex flex-col md:flex-row md:justify-between md:items-center gap-5 border-b p-1"
        >
          <div class="flex flex-col">
            <h2
                class="text-xl md:text-2xl font-semibold font-poppins text-gray-900"
            >
              Exportinformation
            </h2>
          </div>
        </div>
      </div>
      <div class="flex flex-col" slot="body">
        <div>
          <Loading v-if="modalLoading"/>
          <div class="flex flex-col" v-else>
            <div class="flex flex-col mb-1">
              <h1 class="font-semibold text-lg">{{ $t('exportCard.invoiceDate') }}:</h1>
              <span>{{ selectedData.invoice_date }}</span>
            </div>
            <div class="flex flex-col mb-1">
              <h1 class="font-semibold text-lg">{{ $t('exportCard.fileNameType') }}:</h1>
              <span
              >{{ selectedData.file_name }} |
                {{ selectedData.file_type }}</span
              >
            </div>
            <div class="flex flex-col mb-1">
              <h1 class="font-semibold text-lg">{{ $t('exportCard.dateTime') }}:</h1>
              <span>{{ this.selectedDataCreatedAt }}</span>
            </div>
            <div class="flex flex-col mb-1">
              <h1 class="font-semibold text-lg">Status:</h1>
              <span class="flex">
                  <span
                      v-if="selectedData.status.hasOwnProperty('swe_status') && selectedData.status.hasOwnProperty('eng_status')"
                      class="mr-1">
                    <span v-if="GET_LOCALE === 'sv'">{{ selectedData.status.swe_status }}</span>
                    <span v-if="GET_LOCALE === 'en'"> {{ selectedData.status.eng_status }}</span>
                  </span>
                  <span><span v-if="selectedData.finished_without_errors">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6 text-green-500"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                      <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                  <span v-else>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6 text-red-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                    >
                      <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </span></span>
                 </span>
            </div>
            <div class="flex">
              <table class="table">
                <thead>
                <tr>
                  <th>{{ $t('exportCard.type') }}</th>
                  <th>{{ $t('exportCard.message') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, i) in combineMessage(selectedData)" :key="i">
                  <td>
                    <span class="text-red-500 font-semibold" v-if="item.type === 'Error'">{{ item.type }}</span>
                    <span class="text-blue-500 font-semibold" v-else>{{ item.type }}</span>
                  </td>
                  <td>
                    <span v-if="GET_LOCALE === 'sv'">{{ item.sv }}</span>
                    <span v-else>{{ item.en }}</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </CustomNoHeaderModalVue>
    <CustomNoHeaderModalVue
        v-if="openContractCreate"
        width="w-11/12 md:w-1/2"
        :title="''"
        @handleClose="openContractCreate = false"
        :backdropOff="true"
        :close="!true"
    >
      <div class="flex flex-col" slot="header">
        <div
            class="flex flex-col md:flex-row md:justify-between md:items-center gap-5 border-b p-1"
        >
          <div class="flex flex-col">
            <h3
                class="text-xl md:text-xl font-semibold font-poppins text-theme-sidebar"
            >
              {{ $t('exportCard.contractCannotBeFound') }}
            </h3>
          </div>
        </div>
      </div>
      <div class="flex flex-col h-4/6" slot="body">
        <div class="article-action-height">
          <div class="grid grid-cols-3 gap-3">
            <div class="col-span-1"><span class="font-semibold text-sm">{{
                $t('exportCard.contractDescription')
              }}</span>
            </div>
            <div class="col-span-1"><span class="font-semibold text-sm">{{ $t('exportCard.existInAccounting') }}</span>
            </div>
            <div class="col-span-1"><span class="font-semibold text-sm">{{
                $t('exportCard.DoesNotexistInAccounting')
              }}</span></div>
          </div>
          <div class="lg:mt-3 " v-for="(item,i) in contractMainForm">
            <div class="grid grid-cols-1 md:grid-cols-3 gap-3 ">
              <div class="flex flex-col">
              <span class="flex">
                <span class="mr-1 cursor-pointer" :class="{'rotate-180':item.show === true}"
                      v-on:click="openContractccordion(i,item.show)"><svg width="10" height="10"
                                                                          viewBox="0 0 14 14" fill="none"
                                                                          xmlns="http://www.w3.org/2000/svg"><path
                    d="M0 6.4L0.7 7.1L6.7 1.1L12.7 7.1L13.4 6.4L7.1 0H6.4L0 6.4ZM0 12.4L0.7 13.1L6.7 7.1L12.7 13.1L13.4 12.4L7.1 6H6.4L0 12.4Z"
                    fill="black"/>
              </svg>
              </span>
              <span class="font-semibold text-sm">Spelavg alla dagar {{ item.document_number }}</span>
              </span>
              </div>
              <div class="flex flex-col">
                <b-form-group class="center-item">
                  <b-form-radio
                      :name="'radio-exist-' + i"
                      class="c-mb-2"
                      :value="true"
                      v-model="item.exist"
                  />
                </b-form-group>
              </div>
              <div class="flex flex-col justify-center">
                <b-form-group class="center-item">
                  <b-form-radio
                      :name="'radio-exist-'+i"
                      class="c-mb-2"
                      v-model="item.exist"
                      :value="false"
                  />
                </b-form-group>
              </div>
            </div>
            <div class="flex flex-col" v-if="item.show">
              <span class="flex items-center ml-1 mb-2">
                    <div class="w-full md:w-1/3">
                      <span class="text-blue-dark text-sm"
                            v-if="item.show">{{ $t('exportCard.contractTab.existContractTitle') }}</span>
                      <span class="text-blue-dark text-sm" v-else>{{ $t('contractsCard.documentNumber') }}</span>
                    </div>
                    <div class="w-full md:w-1/2">
                      <input
                          v-model="item.document_number"
                          type="text"
                          class="focus:outline-none w-60 h-10 flex custom-border border-blue-300 rounded c-p-2"
                      />
                    </div>
                  </span>
              <span v-if="item.exist === false" class="flex items-center ml-1 mb-1">
                    <div class="w-full md:w-1/3">
                      <span class="text-blue-dark text-sm"
                      >Sales account SE</span
                      >
                    </div>
                    <div class="w-full md:w-1/2">
                      <input
                          v-model="item.se_sale_account"
                          type="text"
                          class="focus:outline-none w-1/2 h-8 flex custom-border border-blue-300 rounded c-p-2"
                      />
                    </div>
                  </span>
              <span v-if="item.exist === false" class="flex items-center ml-1 mb-1">
                    <div class="w-full md:w-1/3">
                      <span class="text-blue-dark text-sm"
                      >Sales account EU</span
                      >
                    </div>
                    <div class="w-full md:w-1/2">
                      <input
                          v-model="item.eu_sale_account"
                          type="text"
                          class="focus:outline-none w-1/2 h-8 flex custom-border border-blue-300 rounded c-p-2"
                      />
                    </div>
                  </span>
              <span v-if="item.exist === false" class="flex items-center ml-1 mb-1">
                    <div class="w-full md:w-1/3">
                      <span class="text-blue-dark text-sm"
                      >Sales account EU (VAT)</span
                      >
                    </div>
                    <div class="w-full md:w-1/2">
                      <input
                          v-model="item.eu_vat_sale_account"

                          type="text"
                          class="focus:outline-none w-1/2 h-8 flex custom-border border-blue-300 rounded c-p-2"
                      />
                    </div>
                  </span>
              <span v-if="item.exist === false" class="flex items-center ml-1 mb-1">
                    <div class="w-full md:w-1/3">
                      <span class="text-blue-dark text-sm"
                      >Sales account Export</span
                      >
                    </div>
                    <div class="w-full md:w-1/2">
                      <input
                          v-model="item.wo_sale_account"
                          type="text"
                          class="focus:outline-none w-1/2 h-8 flex custom-border border-blue-300 rounded c-p-2"
                      />
                    </div>
                  </span>

            </div>
          </div>
        </div>

        <div class="flex items-center justify-end gap-4 mt-1">
          <b-button
              @click="openContractCreate = false"
              variant="outline-danger"
              v-text=" $t('Message.cancel')"
          />
          <b-button
              variant="primary"
              @click="processAccountingContract"
              v-text="processingContract ? $t('Message.loading') : 'Confirm'"
          />
        </div>
      </div>
    </CustomNoHeaderModalVue>
    <CustomNoHeaderModalVue
        v-if="openInvoiceCreate"
        width="w-11/12 md:w-1/2"
        :title="''"
        @handleClose="openInvoiceCreate = false"
        :backdropOff="true"
        :close="!true"
    >
      <div class="flex flex-col" slot="header">
        <div
            class="flex flex-col md:flex-row md:justify-between md:items-center gap-5 border-b p-1"
        >
          <div class="flex flex-col">
            <h3
                class="text-xl md:text-xl font-semibold font-poppins text-theme-sidebar"
            >
              {{ $t('exportCard.invoiceCannotBeFound') }}
            </h3>
          </div>
        </div>
      </div>
      <div class="flex flex-col h-4/6" slot="body">
        <div class="article-action-height">
          <div class="grid grid-cols-3 gap-3">
            <div class="col-span-1"><span class="font-semibold text-sm">{{
                $t('exportCard.invoiceDescription')
              }}</span>
            </div>
            <div class="col-span-1"><span class="font-semibold text-sm">{{ $t('exportCard.existInAccounting') }}</span>
            </div>
            <div class="col-span-1"><span class="font-semibold text-sm">{{
                $t('exportCard.DoesNotexistInAccounting')
              }}</span></div>
          </div>
          <div class="lg:mt-3 " v-for="(item,i) in invoiceMainForm">
            <div class="grid grid-cols-1 md:grid-cols-3 gap-3 ">
              <div class="flex flex-col">
              <span class="flex">
                <span class="mr-1 cursor-pointer" :class="{'rotate-180':item.show === true}"
                      v-on:click="openInvoiceAccordion(i,item.show)"><svg width="10" height="10"
                                                                          viewBox="0 0 14 14" fill="none"
                                                                          xmlns="http://www.w3.org/2000/svg"><path
                    d="M0 6.4L0.7 7.1L6.7 1.1L12.7 7.1L13.4 6.4L7.1 0H6.4L0 6.4ZM0 12.4L0.7 13.1L6.7 7.1L12.7 13.1L13.4 12.4L7.1 6H6.4L0 12.4Z"
                    fill="black"/>
              </svg>
              </span>
              <span class="font-semibold text-sm">Spelavg alla dagar {{ item.document_number }}</span>
              </span>
              </div>
              <div class="flex flex-col">
                <b-form-group class="center-item">
                  <b-form-radio
                      :name="'radio-exist-' + i"
                      class="c-mb-2"
                      :value="true"
                      v-model="item.exist"
                  />
                </b-form-group>
              </div>
              <div class="flex flex-col justify-center">
                <b-form-group class="center-item">
                  <b-form-radio
                      :name="'radio-exist-'+i"
                      class="c-mb-2"
                      v-model="item.exist"
                      :value="false"
                  />
                </b-form-group>
              </div>
            </div>
            <div class="flex flex-col" v-if="item.show">
              <span class="flex items-center ml-1 mb-2">
                    <div class="w-full md:w-1/3">
                      <span class="text-blue-dark text-sm"
                            v-if="item.show">{{ $t('exportCard.contractTab.existContractTitle') }}</span>
                      <span class="text-blue-dark text-sm" v-else>{{ $t('contractsCard.documentNumber') }}</span>
                    </div>
                    <div class="w-full md:w-1/2">
                      <input
                          v-model="item.document_number"
                          type="text"
                          class="focus:outline-none w-60 h-10 flex custom-border border-blue-300 rounded c-p-2"
                      />
                    </div>
                  </span>
              <span v-if="item.exist === false" class="flex items-center ml-1 mb-1">
                    <div class="w-full md:w-1/3">
                      <span class="text-blue-dark text-sm"
                      >Sales account SE</span
                      >
                    </div>
                    <div class="w-full md:w-1/2">
                      <input
                          v-model="item.se_sale_account"
                          type="text"
                          class="focus:outline-none w-1/2 h-8 flex custom-border border-blue-300 rounded c-p-2"
                      />
                    </div>
                  </span>
              <span v-if="item.exist === false" class="flex items-center ml-1 mb-1">
                    <div class="w-full md:w-1/3">
                      <span class="text-blue-dark text-sm"
                      >Sales account EU</span
                      >
                    </div>
                    <div class="w-full md:w-1/2">
                      <input
                          v-model="item.eu_sale_account"
                          type="text"
                          class="focus:outline-none w-1/2 h-8 flex custom-border border-blue-300 rounded c-p-2"
                      />
                    </div>
                  </span>
              <span v-if="item.exist === false" class="flex items-center ml-1 mb-1">
                    <div class="w-full md:w-1/3">
                      <span class="text-blue-dark text-sm"
                      >Sales account EU (VAT)</span
                      >
                    </div>
                    <div class="w-full md:w-1/2">
                      <input
                          v-model="item.eu_vat_sale_account"

                          type="text"
                          class="focus:outline-none w-1/2 h-8 flex custom-border border-blue-300 rounded c-p-2"
                      />
                    </div>
                  </span>
              <span v-if="item.exist === false" class="flex items-center ml-1 mb-1">
                    <div class="w-full md:w-1/3">
                      <span class="text-blue-dark text-sm"
                      >Sales account Export</span
                      >
                    </div>
                    <div class="w-full md:w-1/2">
                      <input
                          v-model="item.wo_sale_account"
                          type="text"
                          class="focus:outline-none w-1/2 h-8 flex custom-border border-blue-300 rounded c-p-2"
                      />
                    </div>
                  </span>

            </div>
          </div>
        </div>

        <div class="flex items-center justify-end gap-4 mt-1">
          <b-button
              @click="openInvoiceCreate = false"
              variant="outline-danger"
              v-text=" $t('Message.cancel')"
          />
          <b-button
              variant="primary"
              @click="processAccountingInvoice"
              v-text="processingInvoice ? $t('Message.loading') : 'Confirm'"
          />
        </div>
      </div>
    </CustomNoHeaderModalVue>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import {ValidationProvider, ValidationObserver} from "vee-validate";
import vSelect from "vue-select";
import DatePicker from "vue2-datepicker";
import useAppConfig from "@core/app-config/useAppConfig";
import moment from "moment"
// import sampleInvoiceExcel from '@/assets/sample_excel/invoices-sample.xlsx'
import CustomNoHeaderModalVue from "@/@core/customComponent/CustomNoHeaderModal.vue";
import {dateMixins} from "@/mixins/dateMixins";

import mdAccessDenied from "@core/components/permission/mdAccessDenied";
import PAGES from "@/services/helpers/constant";

export default {
  name: "invoiceExport",
  mixins: [dateMixins],
  components: {
    vSelect,
    DatePicker,
    ValidationProvider,
    ValidationObserver,
    CustomNoHeaderModalVue, mdAccessDenied
  },
  data() {
    return {
      processingContract: false,
      openContractCreate: false,
      openInvoiceCreate: false,
      processingInvoice: false,
      hidden_fields: {...JSON.parse(JSON.stringify(PAGES['golf_related']['exports']))},
      tab: 1,
      openReceiptModal: false,
      accessDenied: false,
      config: useAppConfig(),
      loading: false,
      selectedData: {},
      processing: false,
      paymentTermResults: [],
      paymentTermRawResults: [],
      // sampleFile : sampleInvoiceExcel,
      invoiceForm: [
        {
          col: 12,
          lg: 6,
          label: "dashboardCard.table.invoiceDateHead",
          type: "date",
          value: "2022-10-20",
          rules: "",
          fieldValue: "invoice_date",
          hidden: false
        },
        {
          col: 12,
          lg: 6,
          label: "dashboardCard.table.dueDateHead",
          type: "date",
          value: "2022-10-20",
          rules: "",
          fieldValue: "due_date",
          hidden: false
        },
        {
          col: 12,
          lg: 6,
          label: "exportCard.ourReference",
          type: "input",
          option: [],
          value: "",
          rules: "",
          fieldValue: "our_reference",
          hidden: false
        },
        {
          col: 12,
          lg: 6,
          label: "exportCard.yourReference",
          type: "input",
          option: [],
          value: "",
          rules: "",
          fieldValue: "your_reference",
          hidden: false
        },
        {
          col: 12,
          lg: 6,
          label: "InvoiceCardCustomerEdit.paymentTerms",
          type: "select",
          value: "",
          label_value: "",
          rules: "",
          fieldValue: "terms_of_payment",
          hidden: false
        },
        {
          col: 12,
          lg: 6,
          label: "exportCard.extraInvoiceRowText",
          type: "input",
          option: [],
          value: "",
          rules: "",
          fieldValue: "extra_invoice_row_text",
          hidden: false,
          maxlength: 49
        },
        {
          col: 12,
          lg: 6,
          label: "exportCard.invoiceFee",
          type: "input",
          option: [],
          value: "",
          rules: "",
          fieldValue: "invoice_fee",
          hidden: false
        },
        //{
        //  col: 12,
        //  lg: 6,
        //  label: "exportCard.verboseFileName",
        //  type: "input",
        //  option: [],
        //  value: "",
        //  rules: "",
        //  fieldValue: "verbose_file_name",
        //  hidden: false
        //},
        {
          col: 12,
          lg: 12,
          label: "InvoiceCardCustomerEdit.invoiceText",
          type: "textarea",
          value: "",
          rules: "",
          fieldValue: "invoice_text",
          hidden: false
        },
      ],
      contractForm: [
        {
          col: 12,
          lg: 4,
          label: "exportCard.contractDate",
          type: "date",
          value: "",
          rules: "",
          fieldValue: "contract_date",
          hidden: false
        },
        {
          col: 12,
          lg: 4,
          label: "exportCard.periodStart",
          type: "date",
          value: "",
          rules: "",
          fieldValue: "period_start_date",
          hidden: false
        },
        {
          col: 12,
          lg: 4,
          label: "exportCard.invoiceInterval",
          type: "input",
          mode: "number",
          value: "",
          rules: "",
          fieldValue: "invoice_interval",
          hidden: false
        },
        {
          col: 12,
          lg: 4,
          label: "InvoiceCardCustomerEdit.paymentTerms",
          type: "select",
          value: "",
          label_value: "",
          rules: "",
          fieldValue: "terms_of_payment",
          hidden: false
        },
        {
          col: 12,
          lg: 4,
          label: "exportCard.contractLength",
          type: "input",
          mode: "number",
          value: "",
          rules: "",
          fieldValue: "contract_length",
          hidden: false
        },
        //{
        //  col: 12,
        //  lg: 4,
        //  label: "exportCard.verboseFileName",
        //  type: "input",
        //  mode: "text",
        //  value: "",
        //  rules: "",
        //  fieldValue: "verbose_file_name",
        //  hidden: false
        //},
        {
          col: 12,
          lg: 8,
          label: "exportCard.divideAmount",
          value: true,
          mode: "text",
          rules: "",
          type: "radio",
          fieldValue: "divide_amount_on_contract_length",
        },
        {
          col: 12,
          lg: 12,
          label: "InvoiceCardCustomerEdit.invoiceText",
          type: "textarea",
          value: "",
          rules: "",
          fieldValue: "invoice_text",
          hidden: false
        },
      ],
      items: [],
      contract_items: [],
      contractMainForm: [],
      invoiceMainForm: [],
      invoiceSelectedData: {},
      contractSelectedData: {},
      queryParams: {
        limit: 30,
      },
      modalLoading: false,
      pagination: {
        currentPage: 1,
        totalProducts: 0,
      },
      fields: [
        {
          key: "eventId",
          label: "exportCard.table.eventId",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 10px",
            width: "90px",
          },
        },
        {
          key: "filename",
          label: "exportCard.table.fileName",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "70px",
          },
        },
        {
          key: "exportTime",
          label: "exportCard.table.exportTime",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "90px",
          },
        },
        {
          key: "status",
          label: "Message.tableHeader.status",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "70px",
          },
        },
        {
          key: "message",
          label: "Notification.message",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "250px",
          },
        },
        {
          key: "action",
          label: "receiptSendCard.action",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "50px",
          },
        },
      ],
      locale: {
        upload: "upload",
        uploadInvoiceTitle: "exportCard.uploadInvoiceTitle",
        alertPermited: "exportCard.alertPermited",
        downloadExcel: "exportCard.downloadExcel",
        messageTitle: "exportCard.messageTitle",
        messageFooter: "exportCard.messageFooter",
        exportLogDetail: "exportCard.exportLogDetails",
        contractExportLogDetail: "exportCard.exportLogDetails",
      },
      file: "",
      newImage: "",
    };
  },
  watch: {
    tab: function (n, o) {
      if (n) {
        this.file = "";
      }
    },
  },
  computed: {
    // messageList() {
    //     return this.$t('exportCard.articleList');
    // },
    message() {
      return this.GET_LOCALE === "sv" ? "message" : "eng_message";
    },
    locale_message() {
      return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message'
    },
    message_event() {
      if (this.GET_LOCALE === "") {
        return this.swedishLang ? "swe_events" : "eng_events";
      } else return this.GET_LOCALE === "sv" ? "swe_events" : "eng_events";
    },
    features() {
      if (this.GET_LOCALE === "") {
        return this.swedishLang ? "features" : "features";
      } else return this.GET_LOCALE === "sv" ? "features" : "features";
    },
    getLang() {
      return this.config.lang.value;
    },
    messageList() {
      return this.$t("exportCard.messageList");
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },
  created() {
    this.disabledFields()
  },
  mounted() {
    // this.invoiceForm[0].value = `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDay()}}`

    this.invoiceForm[0].value = this.getTodayDateFormat();
    this.invoiceForm[1].value = "";
    this.paymentTerms();
    this.getUploadList();
    this.getContractUploadList();

    this.contractForm[0].value = this.getTodayDateFormat().split('T')[0];


  },
  methods: {

    computePaymentTermsSelect(item, type, index) {
      if (type === 'invoice') {
        this.invoiceForm[index].label_value = item.label
        this.invoiceForm[index].value = item.value
      } else {
        this.contractForm[index].label_value = item.label
        this.contractForm[index].value = item.value
      }
    },
    computePaymentTermModalSelect(item, type, index) {
      if (type === 'invoice') {
        this.invoiceMainForm[index].terms_of_payment_text = item.label
        this.invoiceForm[index].terms_of_payment = item.value
      } else {
        this.contractForm[index].terms_of_payment_text = item.label
        this.contractForm[index].terms_of_payment = item.value
      }
    },
    openContractccordion(index, status) {
      this.contractMainForm[index].show = !status
    },
    openInvoiceAccordion(index, status) {
      this.invoiceMainForm[index].show = !status
    },
    validateContractForm: function () {
      let error = false;
      if (this.contractMainForm.length > 0) {
        this.contractMainForm.map((el) => {
          // if (el.article_description === "") {
          //   error = true;
          // }
          if (el.exist) {
            if (el.document_number === "") {
              error = true;
            }
          } else {
            if (
                el.document_number === "" ||
                el.se_sale_account === "" ||
                el.eu_sale_account === "" ||
                el.eu_vat_sale_account === "" ||
                el.wo_sale_account === ""
            ) {
              error = true;
            }
          }
        });
      } else {
        error = true;
      }
      return error;
    },
    validateInvoiceForm: function () {
      let error = false;
      if (this.invoiceMainForm.length > 0) {
        this.invoiceMainForm.map((el) => {
          // if (el.article_description === "") {
          //   error = true;
          // }
          if (el.exist) {
            if (el.document_number === "") {
              error = true;
            }
          } else {
            if (
                el.document_number === "" ||
                el.se_sale_account === "" ||
                el.eu_sale_account === "" ||
                el.eu_vat_sale_account === "" ||
                el.wo_sale_account === ""
            ) {
              error = true;
            }
          }
        });
      } else {
        error = true;
      }
      return error;
    },
    processAccountingContract: function () {
      const payload = {existing_contracts: [], new_contracts: []};
      if (!this.validateContractForm()) {
        this.contractMainForm.map((el) => {
          const obj = {};
          if (el.exist) {
            obj["document_number"] = el.document_number;
            payload.existing_contracts.push(obj);
          } else {
            obj["document_number"] = el.document_number;
            obj["se_sale_account"] = el.se_sale_account;
            obj["eu_sale_account"] = el.eu_sale_account;
            obj["eu_vat_sale_account"] = el.eu_vat_sale_account;
            obj["wo_sale_account"] = el.wo_sale_account;
            payload.new_contracts.push(obj);
          }
        });
        this.processingContract = true;
        this.$useJwt
            .uploads(
                {
                  URL: `/article_processor/${this.contractSelectedData.uuid}/`,
                  method: "put",
                },
                payload
            )
            .then((res) => {
              this.getUploadList();
              this.openContractCreate = false;
              this.processingContract = false;
              this.contractSelectedData = {};
              this.contractMainForm = [];
              // this.$refs.form.reset();
              this.popupMsg(
                  "Pending",
                  res.data[this.message],
                  "CheckIcon",
                  "warning"
              );
            })
            .catch((err) => {
              this.processingContract = false;
              this.popupMsg(
                  this.$t("Message.Failed"),
                  err.response.data[this.message],
                  "AlertTriangleIcon",
                  "danger"
              );
            });
      } else {
        this.popupMsg(
            this.$t("Message.Failed"),
            "Contract fields contains invalid information",
            "AlertTriangleIcon",
            "danger"
        );
      }
    },
    processAccountingInvoice: function () {
      const payload = {existing_invoices: [], new_invoices: []};
      if (!this.validateInvoiceForm()) {
        this.invoiceMainForm.map((el) => {
          const obj = {};
          if (el.exist) {
            obj["document_number"] = el.document_number;
            payload.existing_invoices.push(obj);
          } else {
            obj["document_number"] = el.document_number;
            obj["se_sale_account"] = el.se_sale_account;
            obj["eu_sale_account"] = el.eu_sale_account;
            obj["eu_vat_sale_account"] = el.eu_vat_sale_account;
            obj["wo_sale_account"] = el.wo_sale_account;
            payload.new_invoices.push(obj);
          }
        });
        this.processingInvoice = true;
        this.$useJwt
            .uploads(
                {
                  URL: `/article_processor/${this.invoiceSelectedData.uuid}/`,
                  method: "put",
                },
                payload
            )
            .then((res) => {
              this.getUploadList();
              this.openInvoiceCreate = false;
              this.processingInvoice = false;
              this.invoiceSelectedData = {};
              this.invoiceMainForm = [];
              // this.$refs.form.reset();
              this.popupMsg(
                  "Pending",
                  res.data[this.message],
                  "CheckIcon",
                  "warning"
              );
            })
            .catch((err) => {
              this.processingInvoice = false;
              this.popupMsg(
                  this.$t("Message.Failed"),
                  err.response.data[this.message],
                  "AlertTriangleIcon",
                  "danger"
              );
            });
      } else {
        this.popupMsg(
            this.$t("Message.Failed"),
            "Invoice fields contains invalid information",
            "AlertTriangleIcon",
            "danger"
        );
      }
    },
    reSyncFile(item) {
      if (this.accessDenied === true) {
        return false
      }
      this.$useJwt
          .uploads({URL: `/${item.uuid}/`, method: "put"})
          .then((res) => {
            this.getUploadList();
            this.popupMsg(
                "Pending",
                res.data[this.locale_message],
                "CheckIcon",
                "success"
            );
          })
          .catch((err) => {
            this.processing = false;
            window.Bus.$emit('output-custom-error', err)
          });
    },
    getInvoiceStats(data, value) {
      if (data.length > 0) {
        let d = data[0]
        return d[value]
      }
    },
    templateReference() {
      this.$useJwt
          .memberCare(
              {URL: "newsletter/template", method: "get"},
              {
                responseType: "blob",
                headers: {
                  "Content-Type": "application/xls",
                },
              }
          )
          .then((res) => {
            const content = res.headers["content-type"];
            download(res.data, "termsOfPurchase", content);
          })
          .catch((err) => {
            this.popup(
                err.response.data.message,
                "danger",
                this.$t("Message.Failed"),
                "AlertTriangleIcon"
            );
          });
    },
    async paymentTerms() {
      const {data} = await this.$useJwt.getAllRegisters("termsofpayment");
      this.paymentTermRawResults = data.results
      this.paymentTermResults = data.results.map(el => {
        return {label: el.description, value: el.id}
      })
    },
    getUploadList() {
      this.loading = true;
      this.accessDenied = false
      this.$useJwt
          .uploads({URL: "?file_type=invoices", method: "get"})
          .then((res) => {
            this.loading = false;
            this.items = res.data.data;
          })
          .catch((err) => {
            this.loading = false
            if (err.response.status === 403) {
              this.accessDenied = true
            } else {
              this.accessDenied = false
            }
          });
    },
    getContractUploadList() {
      this.loading = true;
      this.accessDenied = false
      this.$useJwt
          .uploads({URL: "?file_type=contracts", method: "get"})
          .then((res) => {
            this.loading = false;
            this.contract_items = res.data.data;
          })
          .catch((err) => {
            this.loading = false
            if (err.response.status === 403) {
              this.accessDenied = true
            } else {
              this.accessDenied = false
            }
          });
    },
    renderMessage(data) {
      if (data.length > 0) {
        return data[0][this.message_event] &&
        data[0][this.message_event].length > 0
            ? data[0][this.message_event][0]
            : "";
      } else {
        return "";
      }
    },
    renderSyncMessage(data) {
      // if (data.length > 0) {
      //     return `${data[0].error_message}`
      // }
      return "";
    },
    inputImage() {
      const acceptedType = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ];
      const fileType = this.$refs.imageInput.files[0].type.split("/")[1];
      const files = this.$refs.imageInput.files[0];

      if (acceptedType.includes(fileType)) {
        this.file = files;
        // this.formData.append('image', files);
        var reader = new FileReader();

        reader.addEventListener("load", () => {
          this.newImage = reader.result;
        });

        reader.readAsDataURL(files);
      } else {
        this.popupMsg(
            this.$t("Message.Failed"),
            "Invalid file format",
            "AlertTriangleIcon",
            "danger"
        );
      }
    },
    uploadImage() {
      this.$refs.imageInput.click();
    },
    performInvoiceUpload(mode = null) {
      this.$refs.form.validate().then((res) => {
        if (res) {
          if (this.file) {
            const formData = new FormData();

            if (mode === "contracts") {
              this.contractForm.map((el) => {

                if (el.fieldValue === "terms_of_payment") {
                  formData.append(el.fieldValue, parseInt(el.value));
                } else if (el.fieldValue === 'invoice_date') {
                  if (el.value) {
                    formData.append(el.fieldValue, this.parseFormatDate(el.value))
                  }
                } else {
                  formData.append(el.fieldValue, el.value);
                }
              });
            } else {
              this.invoiceForm.map((el) => {
                if (el.fieldValue === "terms_of_payment") {
                  formData.append(el.fieldValue, parseInt(el.value));
                } else if (el.fieldValue === 'invoice_date') {
                  if (el.value) {
                    formData.append(el.fieldValue, this.parseFormatDate(el.value))
                  }
                } else {
                  formData.append(el.fieldValue, el.value);
                }
              });
            }

            formData.append("file", this.file);
            if (mode === "contracts") {
              formData.append("file_type", mode);
            } else {
              formData.append("file_type", "invoices");
            }
            this.processing = true;
            this.invoiceMainForm = []
            this.contractMainForm = []
            this.invoiceSelectedData = {}
            this.contractSelectedData = {}
            this.$useJwt
                .uploads({URL: "/", method: "post"}, formData)
                .then((res) => {
                  this.getUploadList();
                  this.processing = false;
                  this.resetForm();
                  this.$refs.form.reset();
                  this.popupMsg(
                      "Pending",
                      res.data[this.locale_message],
                      "CheckIcon",
                      "warning"
                  );
                  // open modal if we have contract to create
                  if (res.data.code === "40050") {
                    // code signify that an action is needed to be perform on the uploaded article that this being uploaded by the user
                    if (
                        res.data.articles_to_create &&
                        res.data.articles_to_create.length > 0
                    ) {
                      if (mode === "contracts") {
                        this.contractSelectedData = res.data.data;
                        const array = [];
                        res.data.articles_to_create.forEach((element) => {
                          const obj = {
                            document_number: element,
                            show: false,
                            se_sale_account: "",
                            eu_sale_account: "",
                            eu_vat_sale_account: "",
                            wo_sale_account: "",
                            exist: false,
                          };
                          array.push(obj);
                        });
                        this.contractMainForm = array
                        this.openContractCreate = true
                      } else {
                        this.invoiceSelectedData = res.data.data;
                        const array = [];
                        res.data.articles_to_create.forEach((element) => {
                          const obj = {
                            document_number: element,
                            show: false,
                            se_sale_account: "",
                            eu_sale_account: "",
                            eu_vat_sale_account: "",
                            wo_sale_account: "",
                            exist: false,
                          };

                          array.push(obj);
                        });
                        this.invoiceMainForm = array
                        this.openInvoiceCreate = true
                      }

                    }
                  }

                })
                .catch((err) => {
                  this.processing = false;
                  window.Bus.$emit('output-custom-error', err)

                });
          } else {
            this.popupMsg(
                this.$t("Message.Failed"),
                "Vänligen ladda upp en fakturafil",
                "AlertTriangleIcon",
                "danger"
            );
          }
        }
      }).catch(err => {
        //left blank
      });
    },
    parseFormatDate(date_string) {
      return moment(date_string).format('YYYY-MM-DD')

    },
    async openModal(item) {
      if (this.accessDenied === true) {
        return false
      }
      this.modalLoading = true;
      // const receiptData = await this.getReceipts(item.uuid)
      // this.selectedData = item
      this.openReceiptModal = true;
      this.$useJwt
          .uploads({URL: `/${item.uuid}`, method: "get"})
          .then((res) => {
            this.modalLoading = false;
            this.selectedData = res.data.data;
            this.selectedDataCreatedAt = `${this.selectedData.created_at.split('T')[0]}, ${this.selectedData.created_at.split('T')[1].split('.')[0]}`
          })
          .catch((err) => (this.modalLoading = false));
    },
    async getReceipts(uuid) {
      return this.$useJwt.getReceipt({URL: `/${uuid}`, method: "get"});
    },
    resetForm() {
      this.invoiceForm.map((el) => {
        el.value = "";
      });
      this.contractForm.map((el) => {
        el.value = "";
      });
      this.file = "";
    },
    combineMessage(selectData) {
      let sync_error = selectData.sync_errors
      let sync_summary = selectData.sync_summary
      let messages = []
      sync_error.map(item => {
        messages.push({'type': 'Error', 'sv': item.swe_error_message, 'en': item.eng_error_message})
      })
      sync_summary.map(item => {
        if (item.swe_success_messages && item.eng_success_messages) {
          for (let i = 0; i < item.swe_success_messages.length; i++) {
            messages.push({'type': 'Info', 'sv': item.swe_success_messages[i], 'en': item.eng_success_messages[i]})
          }
        }
      })
      return messages
    },
    disabledFields() {
      let hidden_object = this.hiddenResource
      try {
        hidden_object['hidden_fields']['golf_related']['exports'].map(item => {
          this.hidden_fields[item] = true
        })
        this.invoiceForm.map(item => {
          if (this.hidden_fields[item.fieldValue] === false) {
            item.hidden = false
          } else {
            item.hidden = true
          }
        })
        this.contractForm.map(item => {
          if (this.hidden_fields[item.fieldValue] === false) {
            item.hidden = false
          } else {
            item.hidden = true
          }
        })
      } catch (e) {
      }
    },
  },
};
</script>

<style scoped lang="scss">

.resource-selector {
  width: 100%;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/datepicker.scss";
</style>
