<template>
  <html>
  <head>
  </head>
  <body v-on:load="disableContextMenu();" oncontextmenu="return false">
  <div class="wrapper">
    <embed id="pdfframe" src="../../exports/export_guide.pdf#toolbar=0" width="100%" height="100%"
           style="height: 900px;width: 100%;overflow-y: scroll !important;pointer-events: none;cursor:none !important;"
           scrolling="yes">
    <div class="embed-cover"></div>
  </div>
  </body>
  </html>
</template>

<script>
export default {
  name: "pdfFarmer",
  methods: {
    disableContextMenu() {
      window.frames["pdfframe"].contentDocument.oncontextmenu = function () {
        return true;
      };
      var myFrame = document.getElementById('pdfframe');
      myFrame.window.eval('document.addEventListener("contextmenu", function (e) {e.preventDefault();}, false)');
    }
  }
}
</script>

<style scoped>
.embed-cover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  /* Just for demonstration, remove this part */
  opacity: 0.25;
}

.wrapper {
  position: relative;
  overflow: hidden;
}
</style>